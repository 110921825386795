import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Links from './links'

class Team extends Component {
  
  render() {
      const { currentTab, onLinkClick } = this.props;
      return (
        <React.Fragment>
          <div className="first-section">
            <div className="container-fluid">
              <div className="container-limit extra-limit">
                <div className="container">
                  <div className="first-section-inside">
                    <Links 
                      onLinkClick={onLinkClick}
                      currentTab={currentTab}
                    />
                    <div className="left-content">
                      <h1 className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.2s">Trusted by <b>1,000+
                          fast-growing</b> teams</h1>
                    </div>
                    <div className="text-community">
                      <span className="wow fadeInDown" data-wow-duration="0.6s" data-wow-delay="0.3s">Kosovo has one of the
                        youngest populations in the world, 75% is under 35 years old. This gives us a large pool of quality
                        students eager to learn. TIMEFRAME together with our partner GIZ is committed to create a curriculum
                        to enrich our students by teaching them the predominant coding languages used today.</span>
                      <span className="wow fadeInDown" data-wow-duration="0.6s" data-wow-delay="0.3s">In addition to training, our
                        laboratory program focuses on learning by doing for our top students. There they start working on
                        different experimental projects under the supervision of our engineers giving them first hand real
                        life experience, getting them on a path to real projects quicker.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="team-second-section wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
            <div className="team-content-second">
            <div className="overlay"></div>
              <h1 className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.8s"><span className= "bold-txt">17+</span> Developers.</h1>
              <h1 className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.8s"><span className= "bold-txt">2</span> Scrum Masters.</h1>
              <h1 className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.8s"><span className= "bold-txt">3</span> Q&A Engineers.</h1>
              {/* <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.9s">Developing the future, hand in hand with our clients.</span> */}
            </div>
          </div>
          <div className="team-third-section">
            <div className="container-fluid">
              <div className="container-limit extra-limit">
                <div className="container">
                  <div className="left-content">
                    <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.5s"><img
                        src="../img/Group3.png" alt="" />Our Team</span>
                    <h1 className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.6s">Diversity of <b>experience</b>
                      of our management in <b>bussiness</b> and <b>software engineering</b> </h1>
                  </div>
                  <div className="grid team-boxes">
                    <div className="grid-4">
                      <div className="team-img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.1s">
                        <img src="../img/DB__4400.jpg" alt="" />
                      </div>
                      <div className="team-box-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.1s">
                        <span>Gezim Begolli</span>
                        <span>Founder and CEO</span>
                        <span>+383 49 750 850</span><br />
                        <span>gezim.begolli@tframe.de</span>
                      </div>
                    </div>
                    <div className="grid-4">
                      <div className="team-img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.2s">
                        <img src="../img/IMG_6360.jpg" alt="" />
                      </div>
                      <div className="team-box-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.2s">
                        <span>Rinor Hatashi</span>
                        <span>CTO/Tech</span>
                        <span>+383 49 602 999</span><br />
                        <span>rinor.hatashi@tframe.de</span>
                      </div>
                    </div>
                    {/* <div className="grid-4">
                      <div className="team-img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                        <img src="../img/profile.png" alt="" />
                      </div>
                      <div className="team-box-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.3s">
                        <span>Doug Gisby</span>
                        <span>CTO</span>
                      </div>
                    </div>
                    <div className="grid-4">
                      <div className="team-img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.4s">
                        <img src="../img/users/shpat.jpg" alt="" />
                      </div>
                      <div className="team-box-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.4s">
                        <span>Shpat Begolli</span>
                        <span>CAO</span>
                      </div>
                    </div>
                    <div className="grid-4">
                      <div className="team-img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
                        <img src="../img/users/endrit.jpg" alt="" />
                      </div>
                      <div className="team-box-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.5s">
                        <span>Endrit Ahmeti</span>
                        <span>Software Engineer</span>
                      </div>
                    </div>
                    <div className="grid-4">
                      <div className="team-img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">
                        <img src="../img/users/melosi.jpg" alt="" />
                      </div>
                      <div className="team-box-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.6s">
                        <span>Melos Bojku</span>
                        <span>Software Engineer</span>
                      </div>
                    </div>
                    <div className="grid-4">
                      <div className="team-img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">
                        <img src="../img/profile.png" alt="" />
                      </div>
                      <div className="team-box-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.7s">
                        <span>Xhevdet Ferizi</span>
                        <span>PM</span>
                      </div>
                    </div>
                    <div className="grid-4">
                      <div className="team-img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.8s">
                        <img src="../img/profile.png" alt="" />
                      </div>
                      <div className="team-box-content wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.8s">
                        <span>Mustaf Meholli</span>
                        <span>Business Developer</span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
}

export default withRouter(Team);