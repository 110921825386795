import React, { Component } from 'react';
import { withRouter } from 'react-router';

class Links extends Component {
    render() {
        const { currentTab, onLinkClick } = this.props;
        return (
                <React.Fragment>
                    <div className="about-title">
                        <h1>Tech Frame <br/> Software & Solution</h1>
                        <div className="links">
                        <span onClick={onLinkClick.bind(this, 'mission')} className={`${currentTab === 'mission' ? 'active' : '' }`}>Mission</span>
                        <span onClick={onLinkClick.bind(this, 'team')}  className={`${currentTab === 'team' ? 'active' : '' }`} >Team</span>
                        <span onClick={onLinkClick.bind(this, 'services')}  className={`${currentTab === 'services' ? 'active' : '' }`} >Services</span>
                        </div>
                    </div>
                </React.Fragment>
        )
    }
}

export default withRouter(Links);