import http from './httpService';

const {
    REACT_APP_WEB_API_URL: web_api_url,
    // REACT_APP_TOKEN_NAME: tokenName
} = process.env;


export async function post(name, email, subject, message) {
    return await http.post(`${web_api_url}/post-email`, { name, email, subject, message });
}

export default {
    post
}