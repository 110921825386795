import React, { Component } from 'react';
import { withRouter } from 'react-router';

class Community extends Component {
    render() {
      return (
            <React.Fragment>
              <section className="body community">
                <div className="first-section">
                    <div className="container-fluid">
                        <div className="container-limit extra-limit">
                            <div className="container">
                              <div className="first-section-inside">
                                  <div className="about-title">
                                    <h1 className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.3s">Bussiness is built <br/> on our people</h1>
                                    <div className="text">
                                      <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.4s"> Our intention is to grow Kosovo into a tech hub, regionally and globally, and since that required developers who could build specialized clean code, we built the school we needed to create our own superior workforce.</span>
                                    </div>
                                  </div>
                                  <div className="left-content">
                                    <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.5s"><img src="./img/Group3.png" alt="" />What we do</span>
                                    <h1 className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.6s">Everything in world will become <b>tech</b>, we must be <b>ready</b>.</h1>
                                  </div>
                                  <div className="text-community">
                                    <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.7s">Kosovo has one of the youngest populations in the world, 75% is under 35 years old. This gives us a large pool of quality students eager to learn. TIMEFRAME together with our partner GIZ is committed to create a curriculum to enrich our students by teaching them the predominant coding languages used today.</span>
                                    <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.8s">In addition to training, our laboratory program focuses on learning by doing for our top students. There they start working on different experimental projects under the supervision of our engineers giving them first hand real life experience, getting them on a path to real projects quicker.</span>
                                    <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.9s">At TIMEFRAME our trainers are up to date with the most current technologies and constantly keeping their finger on the pulse of programming to be able to anticipate and create what’s coming next</span>
                                  </div>
                                  <div className="grid boxes-community">
                                    <div className="grid-3 box wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.4s">
                                      <div className="logo">
                                        <img src="./img/earth-globe.svg" alt="" />
                                      </div>
                                      <div className="title">
                                        <span>Web Development</span>
                                      </div>
                                      <div className="description">
                                        <span>Learn to plan, design and implement effective web pages by writing HTML and CSS code!</span>
                                        {/* <button type="button" name="button">APPLY NOW</button> */}
                                      </div>
                                    </div>
                                    <div className="grid-3 box wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.5s">
                                      <div className="logo">
                                        <img src="./img/php.svg" alt="" />
                                      </div>
                                      <div className="title">
                                        <span>PHP/Laravel</span>
                                      </div>
                                      <div className="description">
                                        <span>Bring development skills to the next level, with an extreme focus on clean code by learning Laravel!</span>
                                        {/* <button type="button" name="button">APPLY NOW</button> */}
                                      </div>
                                    </div>
                                    <div className="grid-3 box wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.6s">
                                      <div className="logo">
                                        <img src="./img/ios.svg" alt="" />
                                      </div>
                                      <div className="title">
                                        <span>iOS</span>
                                      </div>
                                      <div className="description">
                                        <span>Become an iOS Developer by learning step-by-step how<br/> to build an iPhone<br/> or iPad app!</span>
                                        {/* <button type="button" name="button">APPLY NOW</button> */}
                                      </div>
                                    </div>
                                    <div className="grid-3 box wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.7s">
                                      <div className="logo">
                                        <img src="./img/android-logo.svg" alt="" />
                                      </div>
                                      <div className="title">
                                        <span>Android</span>
                                      </div>
                                      <div className="description">
                                        <span>Become Android Developer by learning step-by-step how<br/> to build an Android app!</span>
                                        {/* <button type="button" name="button">APPLY NOW</button> */}
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
              </section>
            </React.Fragment>
      )
    }
}

export default withRouter(Community);