import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    smoothScroll = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    smoothScrollProjects = () => {
        window.scrollTo(0, 1000)
    }

    render() {
        return (
            <React.Fragment>
                <div className="sixth-section">
                    <div className="container-fluid">
                        <div className="container-limit extra-limit">
                            <div className="container">
                                <div className="sixth-section-inside">
                                    <div className="left-part">
                                        <h1 className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">Ready to <b>get started</b>?</h1>
                                        <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.4s">Find out how Tech Frame works
                                    for you.</span>
                                    </div>
                                    <div className="right-part">
                                        <Link onClick={this.smoothScroll} to="/#projects" className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">Our Works</Link>
                                        <Link onClick={this.smoothScrollProjects} to="/contact" className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">Contact us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="footer">
                    <div className="container-fluid">
                        <div className="container-limit extra-limit">
                            <div className="container">
                                <div className="footer-inside">
                                    <div className="left-part">
                                        <Link to="/about/:header" className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s"><img src="" alt="" /> </Link>
                                        <Link to="/about" className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.4s"><img src="" alt="" /> </Link>
                                        <Link to="/about" className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s"><img src="" alt="" /> </Link>
                                        <Link to="/about" className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s"><img src="" alt="" /> </Link>
                                    </div>
                                    <div className="grid right-part">
                                        <div className="footer-services grid-3">
                                            <span>Connect</span>
                                            <a href="https://www.facebook.com/tframelabs" rel="noopener noreferrer" target="_blank" >Facebook</a>
                                            <a href="https://www.instagram.com/timeframelabs" rel="noopener noreferrer" target="_blank" >Instagram</a>
                                            <a href="https://www.linkedin.com/company/timeframelabs" rel="noopener noreferrer" target="_blank" >LinkedIn</a>
                                        </div>
                                        <div className="footer-services grid-3">
                                            <span>Company</span>
                                            <Link onClick={this.smoothScroll} to="/about">About</Link>
                                            <Link onClick={this.smoothScroll} to="/community">Community</Link>
                                            <Link onClick={this.smoothScroll} to="/about/#services">Services</Link>
                                        </div>
                                        <div className="footer-services grid-3">
                                            <span>Connect</span>
                                            <Link to="/about">FAQs</Link>
                                            <Link onClick={this.smoothScroll} to="/contact">Contact</Link>
                                            <a href="https://www.facebook.com/messages/t/tframelabs" target="_blank" rel="noopener noreferrer" >Messenger</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-copyright ">
                                    <span>© Copyright 2019 Tech Frame</span>
                                    <span>All rights reserved.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default withRouter(Footer);