import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Links from './links'

class Services extends Component {
  render() {
    const { currentTab, onLinkClick } = this.props;

    return (
      <React.Fragment>
        <div className="first-section">
          <div className="container-fluid">
            <div className="container-limit extra-limit">
              <div className="container">
                <div className="first-section-inside">
                  <div className="about-title">
                    <Links
                      onLinkClick={onLinkClick}
                      currentTab={currentTab}
                    />
                  </div>
                  <div className="after-title">
                    <span className="wow fadeInDown" data-wow-duration="0.6s" data-wow-delay="0.2s">At Tech Frame we are dedicated to using the newest standards in development technology while still staying trained in leagzy code
                          and technology so we can support any client equally well.
                              </span>
                  </div>
                  <div className="services-part">
                    <div className="service-one">
                      <div className="part-one">
                        <h1 className="wow fadeInDown" data-wow-duration="0.6s" data-wow-delay="0.3s">UI/UX Design / Branding Services</h1>
                        <span className="wow fadeInDown" data-wow-duration="0.7s" data-wow-delay="0.4s">We
                                offer design and branding services for clients at any stage of
                                their rollout. Whether you need to brand a new company, or rebrand an
                                existing one to bring it to the current state of the art of the digital age.
                                We design software and platforms that meet people’s needs by combining
                                interfaces and workflows to enhance user experience.
                                    </span>
                      </div>
                      <div className="part-two">
                        <img className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.5s" src="..\img\tablet.png" alt="" />
                      </div>
                    </div>
                    <div className="service-two reverse">
                      <div className="part-one">
                        <h1 className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.3s">Web / Mobile Development</h1>
                        <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.4s">
                          Ourteam is able to develop on multiple technologies at once. Anything from html
                          on a CMS like Wordpress. To coding and repairing in Java. To custom
                          developing on .NET <br />
                          We excel in mobile app development wich includes the whole process, from the
                          first line of code to publishing in the store(s) with primary focus in
                          implementing new user interfaces and features together with automated unit
                          and integration tests.
                          </span>
                      </div>
                      <div className="part-two">
                        <img className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.5s" src="..\img\environmental-monitoring.png" alt="" />
                      </div>
                    </div>
                    <div className="service-three">
                      <div className="part-one">
                        <h1 className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.3s">Digitalization</h1>
                        <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.4s">
                        Products we develop are capable of supporting your entire workflow, from
                        scheduling, to location based map integration, to multiple project
                        management, to custom CRM creation to support and increase your sales, to
                        custom APIs that can make any part of your software and site integrate with
                        existing software for accounting and tax management.
                        </span>
                      </div>
                      <div className="part-two">
                        <img className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.6s" src="..\img\intelligent-urbanism.png" alt="" />
                      </div>
                    </div>
                    <div className="service-four reverse">
                      <div className="part-one">
                        <h1 className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.3s">QA Manual and Automation</h1>
                        <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.4s">
                        We have created a team that focuses exclusively on Manual and Automation
                        testing for web and app development. We are dedicated to all aspects of
                        testing from Manual to Automation, using tools like Selenium, SOAPUI,
                        Appium, Cucumber, etc.
                        </span>
                      </div>
                      <div className="part-two">
                        <img className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.6s" src="..\img\smartphone.png" alt="" />
                      </div>
                    </div>
                    <div className="service-five">
                      <div className="part-one">
                        <h1 className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.3s">IT Consulting / Support</h1>
                        <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.4s">
                        You have questions — we've got the answers. Our experts in technology are
                        ready to help you plan the project, analyze the market, or simply guide your
                        team towards a final release.
                        We have many years of experience in customer care service including tech and
                        sales support. Our staff is highly trained and multilingual.
                        </span>
                      </div>
                      <div className="part-two">
                        <img className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.5s" src="..\img\collaborative-innovation-network.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(Services);