import React, { Component } from 'react';
import './assets/css/style.css';
import './assets/css/mobile.css';
import './assets/css/user_layout.css';
import { withRouter } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom' ;
import Home from '../src/components/home/home'
import About from '../src/components/about/about'
import Community from '../src/components/community/community'
import Contact from '../src/components/contact/contact'
import OurWork from '../src/components/ourWork/ourWork'
import NavBar from '../src/components/navbar/navBar'
import Footer from '../src/components/footer/footer'
import { ToastContainer } from 'react-toastify';

class App extends Component {
    render() {
      return (
        <Route render={({ location }) => (
          <React.Fragment>
            <NavBar />
              <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/about' component={About} />
                <Route path='/community' component={Community} />
                <Route path='/contact' component={Contact} />
                <Route path='/our-work' component={OurWork} />
                <Redirect to='/' />
              </Switch>
            <Footer />
            <ToastContainer />
          </React.Fragment>
        )} />
      )
    }
}

export default withRouter(App);
