import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

class FifthSection extends Component {
    smoothScroll = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    render() {
      return (
            <React.Fragment>
                <div className="fifth-section">
                    <div className="container-fluid">
                    <div className="container-limit extra-limit">
                        <div className="container">
                        <div className="fifth-section-inside">
                            <div className="title">
                            <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s"><img
                                src="../img/Group3.png" alt="" />Team</span>
                            <h1 className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.4s">Diversity of <b>experience </b>
                                of our management in <b>business</b> and <b>software engineering</b></h1>
                            <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">Our development team have
                                broad experience and knowledge of different programming languages, both front and back end, which
                                enable us to provide solution for every functionality of your planned software.</span>
                            <Link onClick={this.smoothScroll} to="/about" className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">About us</Link>
                            </div>
                            <div className="grid team">
                            <div className="grid-4 team-inside">
                                <div className="team-img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                                <img src="../img/DB__4400.jpg" alt="" />
                                </div>
                                <div className="team-content wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.4s">
                                <span>Gezim Begolli</span>
                                <span>CEO</span>
                                </div>
                            </div>
                            <div className="grid-4 team-inside">
                                <div className="team-img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
                                <img src="../img/IMG_6360.jpg" alt="" />
                                </div>
                                <div className="team-content wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">
                                <span>Rinor Hatashi</span>
                                <span>CTO/Tech</span>
                                </div>
                            </div>
                            {/* <div className="grid-5 team-inside">
                                <div className="team-img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">
                                <img src="../img/gzimi.png" alt="" />
                                </div>
                                <div className="team-content wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.8s">
                                <span>Gezim Begolli</span>
                                <span>CEO</span>
                                </div>
                            </div> */}
                            </div>
                        </div>
                        <div className="partners">
                            <div className="img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                            <img src="../img/gizi.png" alt="" />
                            </div>
                            <div className="img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.4s">
                            <img src="../img/ceed.png" alt="" />
                            </div>
                            <div className="img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
                            <img src="../img/httpool.png" alt="" />
                            </div>
                            <div className="img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">
                            <img src="../img/telegrafi.png" alt="" />
                            </div>
                            <div className="img wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">
                            <img src="../img/vm3.png" alt="" />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </React.Fragment>
      )
    }
}

export default withRouter(FifthSection);