import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
    {
        original: '../img/hirano.png',
        thumbnail: '../img/hirano.png',
    },
    {
        original: '../img/visio.png',
        thumbnail: '../img/visio.png',
    },
    {
        original: '../img/execruit.png',
        thumbnail: '../img/execruit.png',
    },
    {
        original: '../img/talent.png',
        thumbnail: '../img/talent.png',
    },
];

class FourthSection extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="fourth-section">
                    <div className="container-fluid">
                        <div className="container-limit extra-limit">
                            <div className="container">
                            <div className="fourth-section-inside">
                                <div className="title">
                                <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s"><img
                                    src="../img/Group3.png" alt="" />Projects</span>
                                <h1 className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.4s">The <b>all-in-one </b> spend
                                    management <b>platform</b></h1>
                                </div>
                                <div id="projects" className="projects">
                                    <div className="flexslider wow zoomIn" data-wow-duration="0.5s" data-wow-delay="0.4s">
                                        <ImageGallery items={images} />
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }   
}

export default withRouter(FourthSection);