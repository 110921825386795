import React, { Component } from 'react';
import { withRouter } from 'react-router';

class OurWork extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="work-title">
            <h1><img src="../img/Group3.png" alt=""/> Our Work</h1>
          </div>
          <div className="first-work">
            <div className="first-work-content">
              <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">Hirano</span>
              <p className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">This Web Application helps the company identify process
               deviations and the roots causes and opportunities for process improvement, it uses data insights to optimize processes for the desired
               business outcome and it helps measure the impact of optimization strategies, and mobilizes employees to worl collaboratively in ensuring
               ongoing success.</p>
            </div>
            <div className="first-work-img">
              <img className="wow zoomIn" data-wow-duration="0.5s" data-wow-delay="0.5s" src="../img/hirano.png"
                alt="" />
            </div>
          </div>
          <div className="second-work">
            <div className="second-work-img">
              <img className="wow zoomIn" data-wow-duration="0.5s" data-wow-delay="0.5s" src="../img/talent.png"
                alt="" />
            </div>
            <div className="second-work-content">
              <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">Talent Pool</span>
              <p className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">This web aims to promote new human resources in the labor market, 
              where a focus will be put on the ICT industry primarly.
              The core idea of this so called web platform is to have sompanies and candidates closely together in one area. Since job
              applicants often lack practical working skills many studies strongly suggest that a common web platform for companies and
              job/internship applicants would be highly beneficial. </p>
            </div>
          </div>
          <div className="first-work">
            <div className="first-work-content">
              <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">Visiocoach</span>
              <p className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">Health tech is within our area of  expertise, we proved
                this development for our collaboration with University of Tubingen for their project Visiocoach, where qe developed a medical platform
                for people who have eye problems, the patients improve their sighting through continurd practice with variety of pictures and different
                games. </p>
            </div>
            <div className="first-work-img">
              <img className="wow zoomIn" data-wow-duration="0.5s" data-wow-delay="0.5s" src="../img/visio.png"
                alt="" />
            </div>
          </div>
        </div>
        {/* <div className="first-section about-second">
          <div className="container">
            <div className="first-section-inside">
              <div className="left-content-work wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.5s">
                <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">Hirano</span>
                <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">This Web Application helps the company identify process
                 deviations and the roots causes and opportunities for process improvement, it uses data insights to optimize processes for the desired
                 business outcome and it helps measure the impact of optimization strategies, and mobilizes employees to worl collaboratively in ensuring
                           ongoing success.</span>
              </div>
              <div className="work-right">
                <img className="wow zoomIn" data-wow-duration="0.5s" data-wow-delay="0.5s" src="../img/hirano.png"
                  alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="first-section about-second">
          <div className="container">
            <div className="first-section-inside">
              <div className="left-content-work wow fadeInLeft" data-wow-duration="0.6s" data-wow-delay="0.3s">
                <span>Talent Pool</span>
                <span>This web aims to promote new human resources in the labor market, where a focus will be put on the ICT industry primarly.
                      The core idea of this so called web platform is to have sompanies and candidates closely together in one area. Since job
                      applicants often lack practical working skills many studies strongly suggest that a common web platform for companies and
                                job/internship applicants would be highly beneficial. </span>
              </div>
              <div className="work-right wow fadeInRight" data-wow-duration="0.6s" data-wow-delay="0.3s">
                <img src="../img/talent.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="first-section about-second">
          <div className="container">
            <div className="first-section-inside">
              <div className="left-content-work wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.5s">
                <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">Visiocoach</span>
                <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">Health tech is within our area of  expertise, we proved
                this development for our collaboration with University of Tubingen for their project Visiocoach, where qe developed a medical platform
                for people who have eye problems, the patients improve their sighting through continurd practice with variety of pictures and different
                          games. </span>
              </div>
              <div className="work-right">
                <img className="wow zoomIn" data-wow-duration="0.5s" data-wow-delay="0.5s" src="../img/visio.png"
                  alt="" />
              </div>
            </div>
          </div>
        </div> */}
      </React.Fragment>
    )
  }
}

export default withRouter(OurWork);