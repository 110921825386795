import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
class ThirdSection extends Component {
    render() {
      return (
            <React.Fragment>
                <div className="third-section">
                    <div className="container-fluid">
                    <div className="container-limit extra-limit">
                        <div className="container">
                        <div className="grid third-section-inside">
                            <div className="grid-2 left-part">
                            <div className="title">
                                <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s"><img
                                    src="../img/Group3.png" alt="" />Testimonials </span>
                                <h1 className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.4s">Trusted by <b>1,000+
                                    fast-growing</b> teams</h1>
                                <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">We have many years of
                                experience creating call centers for tech and sales support for international clients. Our staff is
                                highly trained, multilingual, and our prices are highly competitive.</span>
                                <Link to="#" className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">See All</Link>
                            </div>
                            </div>
                            <div className="grid-2 right-part wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.4s">
                                <div className="testimonials " >
                                    <div className="img">
                                    <img src="../img/luka.jpg" alt="" />
                                    </div>
                                    <div className="quotes">
                                    <img src="..\img\quotes.png" alt="" />
                                    </div>
                                    <div className="description">
                                    <span>Since 2017 we are working together in different projects for callwith.me and also for ADCX
                                          they are extended hand of our team while doing great job.</span>
                                    </div>
                                    <div className="name">
                                    <span>Gentian Lluka</span>
                                    </div>
                                </div>
                                <div className="testimonials " >
                                    <div className="img">
                                    <img src="../img/steffe.jpg" alt="" />
                                    </div>
                                    <div className="quotes">
                                    <img src="..\img\quotes.png" alt="" />
                                    </div>
                                    <div className="description">
                                    <span>We are working together since May 2017, the team has built the software which we are using in
                                          day to day basis and we are super satisfied, the team is ready every time to identify our needs
                                          and turn them into software.</span>
                                    </div>
                                    <div className="name">
                                    <span>Steffen König</span>
                                    </div>
                                </div>
                                <div className="testimonials " >
                                    <div className="img">
                                    <img src="../img/marco.jpg" alt="" />
                                    </div>
                                    <div className="quotes">
                                    <img src="..\img\quotes.png" alt="" />
                                    </div>
                                    <div className="description">
                                    <span>Since 2017 we are working together in different projects for callwith.me and also for ADCX
                                          they are extended hand of our team while doing great job.</span>
                                    </div>
                                    <div className="name">
                                        <span>Gentian Lluka</span>
                                    </div>
                                </div>
                                <div className="testimonials " >
                                    <div className="img">
                                    <img src="../img/profile.png" alt="" />
                                    </div>
                                    <div className="quotes">
                                    <img src="..\img\quotes.png" alt="" />
                                    </div>
                                    <div className="description">
                                    <span>The team TIME FRAME team is very flexible and creative, they help on our projects technically
                                        and ideas in different projects.</span>
                                    </div>
                                    <div className="name">
                                        <span>Gentian Lluka</span>
                                    </div>
                                </div>
                                <div className="testimonials ">
                                    <div className="img">
                                    <img src="../img/profile.png" alt="" />
                                    </div>
                                    <div className="quotes">
                                    <img src="..\img\quotes.png" alt="" />
                                    </div>
                                    <div className="description">
                                    <span>The team TIME FRAME team is very flexible and creative, they help on our projects technically
                                        and ideas in different projects.</span>
                                    </div>
                                    <div className="name">
                                        <span>Gentian Lluka</span>
                                    </div>
                                </div>
                                <div className="testimonials " >
                                    <div className="img">
                                    <img src="../img/profile.png" alt="" />
                                    </div>
                                    <div className="quotes">
                                    <img src="..\img\quotes.png" alt="" />
                                    </div>
                                    <div className="description">
                                    <span>The team TIME FRAME team is very flexible and creative, they help on our projects technically
                                        and ideas in different projects.</span>
                                    </div>
                                    <div className="name">
                                        <span>Gentian Lluka</span>
                                    </div>
                                </div>
                                <div className="testimonials " >
                                    <div className="img">
                                    <img src="../img/profile.png" alt="" />
                                    </div>
                                    <div className="quotes">
                                    <img src="..\img\quotes.png" alt="" />
                                    </div>
                                    <div className="description">
                                    <span>The team TIME FRAME team is very flexible and creative, they help on our projects technically
                                        and ideas in different projects.</span>
                                    </div>
                                    <div className="name">
                                        <span>Gentian Lluka</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </React.Fragment>
      )
    }
}

export default withRouter(ThirdSection);