import React, { Component } from 'react';
import { withRouter } from 'react-router';
import FirstSection from './firstSection';
import SecondSection from './secondSection';
import ThirdSection from './thirdSection';
import FifthSection from './fifthSection';
import FourthSection from './fourthSection';

class Home extends Component {
    render() {
      return (
            <React.Fragment>
                  <section className="body">
                    <FirstSection />
                    <SecondSection />
                    <ThirdSection />
                    <FourthSection />
                    <FifthSection />
                  </section>
            </React.Fragment>
      )
    }
}

export default withRouter(Home);