import React, { Component } from 'react';
import { withRouter } from 'react-router';

class SecondSection extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="second-section">
                    <div className="container-fluid">
                        <div className="container-limit extra-limit">
                            <div className="container">
                                <div className="second-section-inside">
                                    <div className="title wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">
                                        <span><img src="../img/Group3.png" alt="" />Services </span>
                                        <h1>Full Range of <b>software engineering</b> services</h1>
                                    </div>
                                    <div className="grid services">
                                        <div className="services-inside grid-3 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                                            <div className="services-inside-img">
                                                <img src="..\img\intelligent-urbanism.png" alt="" />
                                            </div>
                                            <div className="services-content">
                                                <span>Digitalization</span>
                                                <span>We take the needs of our clients seriously, and most of those needs focus on becoming increasingly digital to better compete in the marketplace</span>
                                            </div>
                                        </div>
                                        <div className="services-inside grid-3 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.4s">
                                            <div className="services-inside-img">
                                                <img src="..\img\on-demand-service.png" alt="" />
                                            </div>
                                            <div className="services-content">
                                                <span>Mobile Development</span>
                                                <span>  We excel in mobile app development wich includes the whole process, from the
                                                        first line of code to publishing. </span>
                                            </div>
                                        </div>
                                        <div className="services-inside grid-3 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
                                            <div className="services-inside-img">
                                                <img src="..\img\environmental-monitoring.png" alt="" />
                                            </div>
                                            <div className="services-content">
                                                <span>Web Application Development</span>
                                                <span>Ourteam is able to develop on multiple technologies at once. Anything from html
                                                      on a CMS like Wordpress.</span>
                                            </div>
                                        </div>
                                        <div className="services-inside grid-3 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">
                                            <div className="services-inside-img">
                                                <img src="..\img\tablet.png" alt="" />
                                            </div>
                                            <div className="services-content">
                                                <span>UI/UX Design and Branding Services</span>
                                                <span> We offer design branding services for clients at any stage of their rollout
                                                       Whether you need to brand a new company, or rebrand an existing one to branding
                                                       it to the current state of the art of the digital age.</span>
                                            </div>
                                        </div>
                                        <div className="services-inside grid-3 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">
                                            <div className="services-inside-img">
                                                <img src="..\img\smartphone.png" alt="" />
                                            </div>
                                            <div className="services-content">
                                                <span>QA Manual and Automation</span>
                                                <span>We have created a team that focuses exclusively on Manual and Automation testing for web and app development.</span>
                                            </div>
                                        </div>
                                        <div className="services-inside grid-3 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.8s">
                                            <div className="services-inside-img">
                                                <img src="..\img\collaborative-innovation-network.png" alt="" />
                                            </div>
                                            <div className="services-content">
                                                <span>Support</span>
                                                <span>We have many years of experience creating call centers for tech and sales support for international clients.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(SecondSection);