import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Mission from './mission';
import Team from './team';
import Services from './services';

class About extends Component {
    constructor(props)
    {
      super(props);
      this.state = {
        currentTab: 'mission'
      }
    }
    componentDidMount() {
      if(this.props.location.hash === '#services')
      {
        this.setState({
          currentTab: 'services'
        })
      }else{
        this.setState({
          currentTab: 'mission'
        })
      }
    }

    onLinkClick = (currentTab) => {
      this.setState({
        currentTab: currentTab
      })
    }

    render() {
      const { currentTab } = this.state;
      return (
            <React.Fragment>
              <section className={`body ${currentTab === 'mission' ? 'about' : ' community teams'}`}>
                {currentTab === 'mission' &&
                  <Mission 
                    onLinkClick={this.onLinkClick}
                    currentTab={currentTab}
                  />
                }
                {currentTab === 'team' &&
                  <Team 
                    onLinkClick={this.onLinkClick}
                    currentTab={currentTab}
                  />
                }
                {currentTab === 'services' &&
                  <Services 
                    onLinkClick={this.onLinkClick}
                    currentTab={currentTab}
                  />
                }
              </section>
            </React.Fragment>
      )
    }
}

export default withRouter(About);