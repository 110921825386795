import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Links from './links'

class Mission extends Component {
    render() {
      const { currentTab, onLinkClick } = this.props;
      return (
          <React.Fragment>
              <div className="first-section about-first">
                <div className="container-fluid">
                  <div className="container-limit extra-limit">
                    <div className="container">
                      <div className="first-section-inside">
                        <Links 
                          onLinkClick={onLinkClick}
                          currentTab={currentTab}
                        />
                        <div className="left-content wow fadeInLeft" data-wow-duration="0.6s" data-wow-delay="0.3s">
                          <span><img src="../img/Group3.png" alt="" />Who we are</span>
                          <span>Tech Frame is a small, full service design and development house, with clients across western
                            Europe and the US. Our focus at Tech Frame is on our clients’ digital needs. Together with our clients
                            we create softwares, websites, and apps to help them serve their customers. Our team is young, well
                            educated and with the requisite experience to tackle any project. </span>
                        </div>
                        <div className="right-video wow fadeInRight" data-wow-duration="0.6s" data-wow-delay="0.3s">
                          <img src="../img/7.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="first-section about-second">
                <div className="container-fluid">
                  <div className="container-limit extra-limit">
                    <div className="container">
                      <div className="first-section-inside">
                        <div className="left-content wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.5s">
                          <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s"><img
                              src="../img/Group3.png" alt="" />What we do</span>
                          <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">We develop softwares,
                            websites, and mobile applications for our international clients, with auxiliary services like digital
                            marketing and call center support. Tech Frame is capable of handling a product at any stage of its
                            life cycle. Whether the client needs a brand new product, designed, developed, branded and rolled out,
                            or they need help with existing products, we can handle the task. (Qetu ni link per services).</span>
                        </div>
                        <div className="right-video">
                          <img className="wow zoomIn" data-wow-duration="0.5s" data-wow-delay="0.5s" src="../img/9.jpg"
                            alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="first-section about-third">
                <div className="container-fluid">
                  <div className="container-limit extra-limit">
                    <div className="container">
                      <div className="first-section-inside">
                        <div className="left-content">
                          <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s"><img
                              src="../img/Group3.png" alt="" />What we do</span>
                          <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.4s">What sets us apart in a field
                            full of companies who promise you the world (wide web?) Kosovo is a young country, with a majority of
                            its population under thirty years old and highly educated. After we started Tech Frame, we dedicated a
                            large portion of our efforts on training and specialization of students about to graduate from
                            Computer Science programs at the regional universities.</span>
                          <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">Our intention is to grow
                            Kosovo into a tech hub, regionally and globally, and since that required developers who could build
                            specialized clean code, we built the school we needed to create our own superior workforce.</span>
                        </div>
                        <div className="right-video">
                          <span><img src="../img/Group3.png" alt="" /></span>
                          <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.6s">What sets us apart in a field
                            full of companies who promise you the world (wide web?) Kosovo is a young country, with a majority of
                            its population under thirty years old and highly educated. After we started Tech Frame, we dedicated a
                            large portion of our efforts on training and specialization of students about to graduate from
                            Computer Science programs at the regional universities.</span>
                          <span>Our intention is to grow Kosovo into a tech hub, regionally and globally, and since that required
                            developers who could build specialized clean code, we built the school we needed to create our own
                            superior workforce.</span>
                        </div>
                        <div className="extra-content">
                          <div className="img-extra">
                            <img src="../img/2.jpg" alt="" className="wow zoomIn" data-wow-duration="0.5s"
                              data-wow-delay="0.5s" />
                          </div>
                          <div className="content-extra">
                            <span className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">We are headquartered in
                              Prishtina, in the heart of Europe; this lets us provide all of our services at half the price our
                              clients would pay locally, while still allowing our work to be the highest possible quality. Come to
                              Tech Frame and we can show you all the benefits your company can reap from working with our team.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </React.Fragment>
      )
    }
}

export default withRouter(Mission);