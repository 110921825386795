import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

class NavBar extends Component {
    state = {
        isActive: false 
    }
    onClickBurgerMenu = () => {
        this.setState({
            isActive: !this.state.isActive
        })
    }
    render() {
        const { isActive } = this.state;
        let path = this.props.location.pathname.split('/')[1]
        return (
                <React.Fragment>
                    <section id="header" className={`header ${isActive ? 'active' : ''}`}>
                        <div className="container-fluid">
                        <div className="container-limit extra-limit">
                            <div className="container">
                            <div className="header-inside">
                                <div className="logo">
                                <Link to="/" className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.1s">
                                    <img src="../img/logo.png" alt="" />
                                </Link>
                                </div>
                                <div onClick={this.onClickBurgerMenu} className="burger-menu">
                                    <div className="burger-holder">
                                        <div className="burger-line"></div>
                                        <div className="burger-line"></div>
                                        <div className="burger-line"></div>
                                    </div>
                                </div>
                                <div className="nav">
                                <div className="nav-wrapper">
                                    <Link to="/about" className={`wow fadeInDown ${path === 'about' ? 'active' : ''}`} data-wow-duration="0.5s" data-wow-delay="0.2s">About</Link>
                                    <Link to="/our-work" className={`wow fadeInDown ${path === '1234' ? 'active' : ''}`} data-wow-duration="0.5s" data-wow-delay="0.3s">Work</Link>
                                    <Link to="/community" className={`wow fadeInDown ${path === 'community' ? 'active' : ''}`} data-wow-duration="0.5s"
                                    data-wow-delay="0.4s">Community</Link>
                                    <Link to="/contact" className={`wow fadeInDown ${path === 'contact' ? 'active' : ''}`} data-wow-duration="0.5s" data-wow-delay="0.5s">Contact</Link>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                </React.Fragment>
        )
    }
}

export default withRouter(NavBar);