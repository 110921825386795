import React from 'react';
import { withRouter } from 'react-router';
import Form from '../../all/common/form';
import Joi from 'joi-browser';
import contactService from '../services/contactService'
import * as toast from '../../all/toast'

class Contact extends Form {
  constructor(props)
  {
    super(props);
    this.state = {
        data: {
            name: '',
            email: '',
            subject: '',
            message: ''
        },
        loading:false
    }
  }

  schema = {
      name: Joi.string().required(),
      email: Joi.string().email().required(),
      subject: Joi.string().required(),
      message: Joi.string().required()
  }

  submitForm = () => {
      this.setState({
        loading:true
      })
      const { name, email, subject, message } = this.state.data;
      contactService.post(name, email, subject, message).then((data) => {
        console.log('toast')
        this.setState({
          data: {
            name: '',
            email: '',
            subject: '',
            message: ''
          },
          loading:false
        })
        toast.success('Your message has been sent! Thank You for contacting us.')
        // window.location = "/"
      })
  }

  render() {
    const { loading, data } = this.state
    const { name, email, subject, message } = data
    
    return (
      <React.Fragment>
        <section className="body contact">
          <div className="first-section">
            <div className="container-fluid">
              <div className="container-limit extra-limit">
                <div className="container">
                  <div className="first-section-inside">
                    <div className="about-title">
                      <h1 className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.3s">Looking forward <br />to work together</h1>
                    </div>
                    <div className="contact-form">
                      <div className="left-part">
                        <p className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.4s">{/*Call us <b>(049) 602-999</b> or email Contact us via the online form*/}Contact Us via online form</p>
                        <div className="contact-content">
                          <h1 className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.5s">Office Hours</h1>
                          <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.6s">Monday through Firday</span>
                          <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.7s">9am to 5pm (EST)</span>
                          <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.8s">Saturday</span>
                          <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.9s">12pm to 4pm (EST)</span>
                        </div>
                      </div>
                      <div className="right-part">
                        <form onSubmit={this.handleSubmit} >
                          <div className="input-name">
                            <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.3s">Your Name</span>
                            <input onChange={this.handleChange} className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.4s" type="text" name={'name'} value={name} />
                          </div>
                          <div className="input-email">
                            <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.5s">Your Email</span>
                            <input onChange={this.handleChange} className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.6s" type="email" name={'email'} value={email} />
                          </div>
                          <div className="input-subject">
                            <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.7s">Subject</span>
                            <input onChange={this.handleChange} className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.8s" type="text" name={'subject'} value={subject} />
                          </div>
                          <div className="input-message">
                            <span className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="0.9s">Your Message</span>
                            <textarea onChange={this.handleChange} className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="1s" name={'message'} rows="8" cols="80" value={message}></textarea>
                          </div>
                          {this.renderSubmitButton(`submit`, loading)}
                          {/* <button className="wow fadeInDown" data-wow-duration="0.5s" data-wow-delay="1.1s" type="submit">SUBMIT</button> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default withRouter(Contact);